<template>
    <div class="mt-3 row">
        <div class="col-12">
            <div class="card mb-4">
                <div class="card-header pb-0">
                    <h6>Reservations List</h6>
                </div>
                <div class="card-body px-0 pt-0 pb-2">
                    <div class="table-responsive p-0">

                        <!-- <div class="m-4">
                            <b-row>

                                <b-col cols="12" sm="12">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <b-form-input
                                            class="d-inline-block mr-1"
                                            placeholder="Search"
                                            :disabled="loading"
                                        />
                                    </div>
                                </b-col>

                                <b-col cols="12" md="2" class="d-flex align-items-end justify-content-end mb-1 mb-md-0">
                                    <soft-button
                                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                        variant="gradient"
                                        color="primary"
                                        class="d-block w-100"
                                    >
                                        <span>Add New Venue</span>
                                    </soft-button>
                                </b-col>

                            </b-row>
                        </div> -->

                        <b-table
                            :items="reservations"
                            :fields="[
                                { key: 'clientName', label: 'Name', sortable: false },
                                { key: 'clientEmail', label: 'Email', sortable: false, class: 'text-center' },
                                { key: 'clientPhoneNumber', label: 'Phone', sortable: false, class: 'text-center' },
                                { key: 'reservationStatus', label: 'Status', sortable: false, class: 'text-center' },
                                { key: 'reservationDate', label: 'Date/Time', sortable: false, class: 'text-center' },
                                { key: 'created_at', label: 'Received', sortable: true, class: 'text-center' },
                                { key: 'guestsCount', label: 'Guests', sortable: false, class: 'text-center' },
                                // { key: 'id', label: '', sortable: false, class: 'text-center' },
                            ]"
                            hover
                            responsive
                            show-empty
                            :per-page="pagination.per_page"
                            :busy="loading"
                            primary-key="id"
                            ref="table"
                            select-mode="multi"
                        >
                            <!-- selectable @row-selected="onRowSelected" -->

                            <template #table-busy>
                                <div class="text-center text-primary my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <p>
                                        <strong>Loading...</strong>
                                    </p>
                                </div>
                            </template>

                            <template #cell(clientName)="{ item }">
                                <router-link :to="{ name: 'MemberInfo', params: { id: item.memberId } }">
                                    <div class="d-flex px-2 py-1">
                                        <soft-avatar
                                        type="member"
                                            :img="item.avatar"
                                            size="sm"
                                            border-radius="lg"
                                            class="me-3"
                                            :alt="item.clientName"
                                        />
                                        <div class="d-flex flex-column justify-content-center">
                                            <h6 class="flex text-sm p-0 m-0">{{ item.clientName || 'N/A' }}</h6>
                                            <!-- <p class="text-xs text-secondary mb-0">{{ item.address || 'N/A' }}</p> -->
                                        </div>
                                    </div>
                                </router-link>
                            </template>

                            <template #cell(clientEmail)="{ item }">
                                <soft-button
                                    v-if="item.clientEmail"
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    v-b-tooltip.hover.v-success
                                    v-clipboard:copy="item.clientEmail"
                                    v-clipboard:success="() => onCopy('Email Copied Successfully')"
                                    variant="gradient"
                                    circle
                                    color="success"
                                    :title="item.clientEmail"
                                >
                                    <i class="fa fa-envelope-o"></i>
                                </soft-button>
                                <span v-else>N/A</span>
                            </template>

                            <template #cell(clientPhoneNumber)="{ item }">
                                <soft-button
                                    v-if="item.clientPhoneNumber"
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    v-b-tooltip.hover.v-primary
                                    v-clipboard:copy="item.clientPhoneNumber"
                                    v-clipboard:success="() => onCopy('Phone Copied Successfully')"
                                    variant="gradient"
                                    circle
                                    color="primary"
                                    :title="item.clientPhoneNumber"
                                >
                                    <i class="fa fa-phone"></i>
                                </soft-button>
                                <span v-else>N/A</span>
                            </template>

                            <template #cell(reservationStatus)="{ item }">
                                <SoftBadge v-if="item.reservationStatus === 'Inquiry'" color="danger" variant="gradient" size="sm">Inquiry</SoftBadge>
                                <SoftBadge v-else-if="item.reservationStatus === 'Canceled'" color="dark" variant="gradient" size="sm">Canceled</SoftBadge>
                                <pre v-else>{{ item.reservationStatus }}</pre>
                            </template>

                            <template #cell(reservationDate)="{ item }">
                                <span class="text-center">{{ $moment(item.reservationDate).format('MM/DD/YYYY') }}</span>
                                <br>
                                <span class="text-center">{{ $moment(item.timeReservedFrom, 'hh:mm:ss').format('h:mm A') }}</span>
                            </template>

                            <template #cell(created_at)="{ item }">
                                <span class="text-center">{{ $moment(item.created_at).format('MM/DD/YYYY') }}</span>
                                <br>
                                <span class="text-center">{{ $moment(item.created_at).format('h:mm A') }}</span>
                            </template>

                            <template #cell(id)="{ item }">
                                <soft-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="gradient"
                                    circle
                                    color="info"
                                    @click="onCopy(item)"
                                    v-b-tooltip.hover.v-info
                                    title="Edit"
                                >
                                    <i class="fa fa-edit"></i>
                                </soft-button>

                                <soft-button
                                    class="mx-1"
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="gradient"
                                    circle
                                    color="danger"
                                    @click="onCopy(item)"
                                    v-b-tooltip.hover.v-danger
                                    title="Delete"
                                >
                                    <i class="fa fa-trash"></i>
                                </soft-button>

                            </template>

                        </b-table>

                    </div>
                </div>
            </div>

            <pagination
                v-if="!loading"
                :currentPage="pagination.current_page"
                :lastPage="pagination.last_page"
                @paginate="paginate($event)"
            />

        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from 'vuex'
import { useToast } from 'vue-toastification'
import SoftBadge from '@/components/SoftBadge'
import SoftAvatar from '@/components/SoftAvatar'
import Pagination from '@/components/Pagination'
import SoftButton from '@/components/SoftButton'
import {
    BCol,
    BRow,
    BTable,
    BButton,
    BSpinner,
    BFormInput,
    BFormCheckbox,
} from 'bootstrap-vue'

export default {

    name: 'VenueReservations',

    props: ['id'],

    setup() {

        // Get toast interface
        const toast = useToast()

        // Make it available inside methods
        return { toast }

    },

    data() {
        return {
            selected: [],
            loading: true,
            pagination: {
				per_page: this.$route?.query?.limit || 20,
				current_page: this.$route?.query?.page || 1,
				last_page: 100,
			}
        }
    },

    components: {
        BCol,
        BRow,
        BTable,
        BButton,
        BSpinner,
        SoftBadge,
        SoftAvatar,
        SoftButton,
        Pagination,
        BFormInput,
        BFormCheckbox,
    },

    computed: {
        ...mapState({
            reservations: state => state.venue.reservations
        })
    },

    beforeMount() {
        this.getData()
    },

    methods: {

        ...mapActions({
            getReservations: `venue/getVenueReservationsAction`,
        }),

        getData() {
            this.getReservations({ id: this.id, ...this.pagination }).then(({ data }) => {
				this.pagination = this.setCurrentPagePagination(data)
			}).finally(() => {
				this.loading = false
			})
        },

        paginate(event) {
			this.loading = true

            this.pagination.current_page = event
			this.$router.push({
                params: {
                    id: this.id
                },
				query: {
					...this.$route.query,
					page: event
				}
			})

            this.getData()
        },

        onCopy(message, type = 'success') {
            this.toast[type](message)
        },

        onRowSelected(items) {
            this.selected = items
        },

        selectAllRows() {
            this.$refs.table.selectAllRows()
        },

        clearSelected() {
            this.$refs.table.clearSelected()
        },

    },
}
</script>
